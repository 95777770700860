@import '../../../index.scss';

.video-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  max-height: 300px; // Die maximale Höhe, die Sie bevorzugen
  overflow-y: auto; // Erlaubt das Scrollen bei Überschreitung der max-height
}

.video-item {
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.video-text {
  display: flex;
  flex-direction: column;
  width: 99%; // Feste Breite für das video-text Element
}

.video-title {
  font-size: 16px;
  //font-weight: bold;
  margin-top: 5px;
}

.video-description {
  font-size: 12px;
  margin-top: 3px;
  text-align: left;
  color: #777;
}

.video-item img {
  max-width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-left: 10px;
}


.video-item:hover {
  background-color: #e0e0e0;
}

.video-item.active {
  background-color: #c0c0c0;
}

.video-text {
  display: flex;
  flex-direction: column;
}


.toggle-loop {
  cursor: pointer;
  margin-top: 20px;
}

.playlist-title {
  font-size: 1.25rem; // Die gewünschte Schriftgröße für den Titel
  font-weight: bold; // Die gewünschte Schriftstärke für den Titel
  text-align: center;
  margin-bottom: 10px;
}

.playlist-container {
  //position: absolute;
  //bottom: 0;
  //left: 50%;
  //transform: translateX(-50%);
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  width: 640px;
  min-width: 640px;
  margin-top: 20px;
  //margin-left: 112px;
  //margin-right: 112px;
  //justify-content: center;
  align-items: center;
  box-sizing: border-box; // Fügt diese Zeile hinzu, um sicherzustellen, dass Padding und Border innerhalb der Breite enthalten sind
}

.big-video {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
}


.toggle-loop-button {
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%
}

.toggle-loop-button:hover {
  background-color: $color-red-dark;
}


.form-container {
  display: block;
  box-sizing: border-box;
  gap: 20px;
  /*width: 960px;*/
  max-width: 100%;
  /* mehr Platz für das Formular */
  margin-top: auto;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 25px;
  padding-bottom: 25px;
  /*border: 1px solid #ddd;
  border-radius: 10px;
  align-items: center;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Fügt eine glatte Übergangsanimation hinzu */
}

.form-container:hover {
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.10), 0 3px 4px rgba(0, 0, 0, 0.06);*/
}